import { useRouteError } from "react-router-dom";
import React from "react";

const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);
  return (
    <div className="flex items-center justify-center w-screen h-screen m-auto">
      <p className="text-2xl">
        Dark night falls. Nowhere else but{" "}
        <a className="hover:underline" href="/">
          {" "}
          here
        </a>
        .
      </p>
    </div>
  );
};

export default ErrorPage;

import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import React, { useState, useEffect } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

const ContactPage = () => {
  useEffect(() => {
    document.title = "Kaizo Kim - Contact";
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", "Contact");
  }, []);
  return (
    <>
      <Header />
      <div className="flex flex-col mx-auto md:mt-8 items-center w-full ">
        <h1 className="font-sans text-2xl lg:text-3xl text-center font-bold mb- md:text-left pb-2">
          Contact
        </h1>
        <div className="font-mono text-base md:text-left text-center px-8">
          Booking / general Inquiries: hello@kaizokim.com
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactPage;

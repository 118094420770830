import React from "react";
import Header from "./components/common/Header";
import Home from "./pages/HomePage";
import Footer from "./components/common/Footer";

function App() {
  return (
    <>
      <div className="h-full">
        <Header />
        <div className="relative z-10 h-screen max-w-screen-xl overflow-auto mx-auto mb-96 items-center justify-center">
          <Home />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default App;

import ArcadeGame from "../components/misc/ArcadeGame";
import Header from "../components/common/Header";
import ProgramWindow from "../components/window/ProgramWindow";
import { useEffect } from "react";

const ArcadePage = () => {
  useEffect(() => {
    document.title = "Kaizo Kim - Play";
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", "Play games by the artist.");
  }, []);

  return (
    <>
      <Header> </Header>

      <div
        id="arcade-container"
        className="max-w-3xl justify-center items-center mx-auto"
      >
        <ProgramWindow title="ping.exe">
          <ArcadeGame></ArcadeGame>
        </ProgramWindow>
      </div>
    </>
  );
};

export default ArcadePage;

import React from "react";
import { useState } from "react";
import { FaInstagram, FaSpotify, FaTiktok, FaBandcamp } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import {} from "react-icons/fa";
import { SiApplemusic } from "react-icons/si";
import NewsletterModal from "./newsletterModal";

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <NewsletterModal
        isOpen={isModalOpen}
        onClose={closeModal}
      ></NewsletterModal>
      {/* <NewsletterModal isOpen={isModalOpen} onClose={closeModal}/> */}
      <footer
        role="contentinfo"
        className="w-full grid grid-cols-6 p-4 z-20 bg-black fixed bottom-0 flex-row justify-center items-center"
      >
        <nav className="flex row md:col-start-2 md:col-span-4 m-auto">
          <a href="https://instagram.com/kaizo.kim" className="icon-link">
            <FaInstagram className="icon size-5" />
          </a>
          <a href="https://www.tiktok.com/@kaizokim" className="icon-link">
            <FaTiktok className="icon size-5" />
          </a>

          <a
            href="https://open.spotify.com/artist/5eP5MGkKjv5T1vpZHgwPX0?si=RAtB7dEPRQOtmx0fjDThUA"
            className="icon-link"
          >
            <FaSpotify className="icon size-5" />
          </a>

          <a
            href="https://music.apple.com/artist/1754676683"
            className="icon-link"
          >
            <SiApplemusic className="icon size-5" />
          </a>
          <a href="https://kaizokim.bandcamp.com" className="icon-link">
            <FaBandcamp className="icon size-5" />
          </a>
        </nav>
        <nav class="font-sans col-start-6 sm:flex sm:flex-row flex-col items-center text-center justify-self-end mr-5 space-x-3 text-white md:text-lg text-sm">
          <a
            role="button"
            onClick={openModal}
            className="text-white hover:cursor-pointer"
          >
            {" "}
            subscribe{" "}
          </a>
          <a className="text-white" href="/contact">
            {" "}
            contact
          </a>
          <a className="text-white" href="/about">
            {" "}
            press
          </a>
        </nav>
      </footer>
    </>
  );
};

export default Footer;

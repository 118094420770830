import ProgramWindow from "./ProgramWindow";
import Draggable from "react-draggable";
import WrapperWindow from "./WrapperWindow";
import pingImage from "../../images/ping/ping-artwork.png";
import Video from "../epk/Video";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const ReleaseWindow = () => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const navigate = useNavigate();
  function handleVideoClick() {
    setIsVideoOpen(!isVideoOpen);
  }

  function handlePlayGame() {
    navigate("/play");
  }

  return (
    <Draggable className="relative z-10">
      {/* margins controlled within component to offset footer and display one window at a time */}
      <div className="h-full mb-80">
        <WrapperWindow title={"Ping! project (final)"}>
          <div className="w-full flex flex-col md:flex-row md:space-x-24 h-full space-y-4 ">
            <div className="w-full lg:size-1/3">
              <ProgramWindow
                title="Ping! out now"
                hasVideo={false}
                FolderTitle="release info"
              >
                <div>
                  <p style={{ textAlign: "center" }}> </p>
                  <div className="flex flex-col space-y-3 h-full ">
                    <a
                      className="text-black"
                      href="https://ffm.to/ping"
                      rel="noopener noreferrer"
                      target="_blank:"
                    >
                      <button className="w-full md:w-2/3 h-9">Stream </button>
                    </a>

                    {/* <button
                      onClick={handleVideoClick}
                      className="w-full md:w-2/3 h-9"
                    >
                      Watch music video
                    </button> */}

                    {/* <button
                      onClick={handlePlayGame}
                      className="w-full md:w-2/3 h-9"
                    >
                      Play game
                    </button> */}
                    {/* <button className="w-full md:w-2/3 h-9">
                      Download ringtone
                    </button> */}
                  </div>
                </div>
              </ProgramWindow>
            </div>
            {/* Music Video (if there is one) */}

            <div
              className={
                isVideoOpen ? "absolute top-24 w-1/2 h-1/2 z-20 " : "hidden"
              }
            >
              <ProgramWindow
                title="Ping!MV.mp4"
                handleVideoClick={handleVideoClick}
              >
                <Video></Video>
              </ProgramWindow>
            </div>
            <div className="w-full lg:size-1/2 lg:items-start lg:justify-start">
              <ProgramWindow
                title="PingAlbumArt.png"
                hasVideo={false}
                FolderTitle="artwork"
              >
                <div className="lg:size-2/3 size-40 mx-auto border border-slate-500">
                  <div className="">
                    <img src={pingImage} alt="Music Artwork" className="" />
                  </div>
                </div>
              </ProgramWindow>
            </div>
          </div>
        </WrapperWindow>
      </div>
    </Draggable>
  );
};
export default ReleaseWindow;

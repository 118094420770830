import React, { useState, useEffect } from "react";
import ArtistBio from "../components/epk/ArtistBio";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import Gallery from "../components/epk/Gallery";
import headshot from "../images/EPK/mall-counter-headshot.jpg";
import Video from "../components/epk/Video";
import { epkLink } from "../utils/links";

function About() {
  const [isVisible, setIsVisible] = useState(true);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    document.title = "Kaizo Kim - About";
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", "About the artist");
  }, []); // Empty dependency array means this runs once on mount

  return (
    <div className="h-full overflow-auto md:mb-96">
      <Header></Header>
      <div className="max-w-screen-2xl mx-auto ">
        <div className="h-full w-full flex flex-col px-12">
          <div className="flex lg:flex-row flex-col items-center justify-center text-center ">
            <div className="w-full space-y-4">
              <ArtistBio></ArtistBio>
              <p className="text-xs self-end">
                Full EPK available <a href={epkLink}>here.</a>
              </p>
            </div>
            {/* <ProgramWindow title={"bio.txt"} handleVideoClick={handleClick}> 
        <img src={statement} alt="Kaizo (改造, 'kaizō', meaning 'modification', 'rebuild' or 'reconfiguration')"/> 
        </ProgramWindow> */}
            <div className="mt-3 lg:p-10 md:size-2/3 lg:size-full h-full w-full">
              <img alt="Kaizo Kim" className="" src={headshot}></img>

              <p></p>
            </div>
          </div>
        </div>

        {/* YouTube section
    <div className="w-full flex-col items-center justify-center text-center"> 
    <h2 className="mb-10"> Videos </h2>
      <div className="grid grid-cols-2 mb-96">
        <Video url="https://www.youtube.com/embed/v=XGx1HvLV_NQ"></Video>

      </div>

      </div> */}
      </div>

      <Footer> </Footer>
    </div>
  );
}

export default About;

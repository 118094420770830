
import React from 'react'
import SubstackWidget from './SubstackWidget'
function NewsletterModal({isOpen, onClose}) {

  if (!isOpen) return null;
  
    return (


        
 <div class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">

<div class="fixed w-screen h-screen inset-0 bg-gray-900 bg-opacity-60">
    <div className="flex min-h-full items-center justify-center m-auto">
        <div className="px-20 p-12 bg-white space-y-3"> 
        <p className="text-lg font-bold font-['Inconsolata']" > join. it's free :) </p>

< SubstackWidget/>
<div role="button" className="btn first-line:font-sans" onClick={onClose}> Close </div>
</div>
</div>
</div>

</div> 
    )

}


export default NewsletterModal

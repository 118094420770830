import React from "react";
const images = require.context("../../images/EPK", false, /\.(png|jpe?g|svg)$/);
const imageList = images.keys().map((image) => images(image));

function Gallery() {
  return (
    <div className="columns-3 gap-5 ">
      {imageList.map((src, index) => (
        <img key={index} src={src} alt="Gallery Item" />
      ))}
    </div>
  );
}

export default Gallery;

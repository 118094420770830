import * as React from "react";
import dice from "../../images/icons/dice.jpg";
import { useState } from "react";
import quotes from "../../utils/quotes.js";
import SplitType from "split-type";
import gsap from "gsap";

import HelpWindow from "../window/HelpWindow.jsx";

function Wish() {
  const [index, setIndex] = useState(0);

  function handleClick() {
    let randomNumber = Math.floor(Math.random() * quotes.length);
    setIndex(randomNumber);
    var text = new SplitType("#quote");

    let characters = document.querySelectorAll(".char");

    for (let i = 0; i < characters.length; i++) {
      characters[i].classList.add("translate-y-full");
    }

    gsap.to(characters, {
      y: 0,
      stagger: 0.02,
      delay: 0.0,
    });
  }

  return (
    <div className="justify-center items-center lg:min-h-96">
      <div className="flex flex-col items-center justify-center space-y-10 my-auto xl:mx-96 ">
        <div className="font-sans font-bold text-3xl">take a wish.</div>
        <HelpWindow title="help.txt">
          <div className="p-4 h-36 ">{quotes[index]}</div>
        </HelpWindow>

        <div
          role="button"
          onClick={handleClick}
          className="hover:cursor-pointer quick-spin transition ease-in-out hover:-translate-y-1 hover:scale-110"
        >
          <img src={dice} className="size-10" alt="roll me" />
        </div>
      </div>
    </div>
  );
}

export default Wish;

import React from "react";

const ArtistBio = () => {
  return (
    <section className="">
      <div className="flex flex-col items-center artist-bio-content space-y-2">
        <h2 className="font-sans text-2xl font-semibold">About the Artist</h2>
        <p className="lg:text-2xl md:text-lg text-sm text-left ">
          Kaizo Kim is an artist who explores themes of isolation, addiction,
          and the consequences of growing up with unrestricted Internet access
          through playful, precise pop music. Writing, recording, and producing
          all himself, his music poses a simple question: “Was I a happy kid?”
        </p>
      </div>
    </section>
  );
};

export default ArtistBio;

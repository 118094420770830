const quotes = [
  "It is not enough to give one an axe and call them a lumberjack. For they must chop for 15 weeks.",
  "I hope you see a nice cloud today.",
  "Enjoy a small corner piece of 86% dark chocolate.",
  "You are bamboo.",
  "Those are some beautiful fingers.",
  "When you meet Taylor Swift, cry and thank her.",
  "To a beetle, you are a shiny bottlecap.",
  "May you observe scallops in New Bedford, MA.",
  "Make use of that half-banana.",
  "The Hannah gates machine will chew through you.",
];

export default quotes;

import React from "react";
import logo from "../../images/logos/KaizoLogo-Light.png";
import colorLogo from "../../images/logos/KaizoLogo-Color.png";
import { FiMenu } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { listenLink } from "../../utils/links";

import { shopLink } from "../../utils/links";

const Header = () => {
  const navigate = useNavigate();
  const [menuState, setMenuState] = useState(false);

  const handleClickLogo = () => {
    navigate("/");
  };

  const handleMenuClick = () => {
    setMenuState(!menuState);
  };

  return (
    <header role="banner" className="relative bg-white z-40">
      <nav className="px-4 backdrop-blur-md flex mx-auto justify-between md:px-10 w-full py-9 items-center">
        {/* Logo */}
        <div id="header-container" className="">
          <img
            id="logo-image"
            className="w-48 sm:w-80 cursor-pointer lg:w-2/5 "
            src={colorLogo}
            alt="Kaizo Kim"
            onClick={handleClickLogo}
          />
        </div>
        {/* mobile menu icon */}
        {!menuState ? (
          <div id="menu" className="sm:hidden flex">
            <div
              role="button"
              className="cursor-pointer"
              onClick={handleMenuClick}
            >
              <FiMenu className="size-6" />
            </div>
          </div>
        ) : null}

        {/* mobile menu */}
        {menuState ? (
          <div className="absolute top-0 z-auto bg-white w-full text-4xl flex flex-col justify-content-center animate-open-menu">
            <div
              role="button"
              class="self-end pt-7 mr-4 cursor-pointer"
              onClick={handleMenuClick}
            >
              <IoMdClose />
            </div>
            <nav
              className="font-sans flex flex-col min-h-screen items-center space-y-4"
              aria-label="mobile"
            >
              <a
                className="text-black"
                href="https://kaizokim.bandcamp.com/?from=viewsite_dashboard"
                rel="noopener noreferrer"
              >
                Listen
              </a>

              <a className="text-black" href="/watch" rel="noopener noreferrer">
                Watch
              </a>
              <a className="text-black" href={shopLink}>
                Shop
              </a>
            </nav>
          </div>
        ) : null}

        {/* desktop menu */}
        <ul className="hidden mr-5 sm:flex flex-grow justify-end space-x-12">
          <li className="outline-none text-3xl font-['NeueHaasDisplayRoman'] hover:underline">
            <a
              className="text-black"
              href={listenLink}
              rel="noopener noreferrer"
              target="_blank:"
            >
              listen
            </a>
          </li>
          <li className="outline-none text-3xl font-['NeueHaasDisplayRoman'] hover:underline">
            <a className="text-black" href="/watch" rel="noopener noreferrer">
              watch
            </a>
          </li>
          <li className="text-3xl font-['NeueHaasDisplayRoman'] hover:underline visited:none">
            <a
              className="text-black focus:outline-none"
              href="https://kaizokim.bandcamp.com/?from=viewsite_dashboard"
              rel="noopener noreferrer"
              target="_blank"
            >
              shop
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;

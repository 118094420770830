import React from "react";
import ReleaseWindow from "../components/window/ReleaseWindow";
import SubstackWidget from "../components/common/SubstackWidget";
import Draggable from "react-draggable";
import ProgramWindow from "../components/window/ProgramWindow";
import statement from "../images/EPK/statement.png";
import { useEffect } from "react";
import { trailingCursor } from "cursor-effects";

const Home = () => {
  useEffect(() => {
    document.title = "Kaizo Kim Official";
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", "Home page");
  }, []); // Empty dependency array means this runs once on mount

  new trailingCursor({ particles: 9, rate: 0.6 });

  return (
    <div
      role="main"
      className="h-[38rem] md:h-[38rem] lg:h-[42rem] w-full flex-row items-center p-5"
    >
      <div className="items-center justify-center">
        <ReleaseWindow></ReleaseWindow>
      </div>
    </div>

    // {/*  hide unless user triggers button  */}
    // {/* <div className="absolute animation-container z-10 hidden">
    //   <div className="ball"></div>
    // // </div> */}
  );
};

export default Home;

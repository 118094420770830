import React from "react";
import Header from "../components/common/Header";
import Wish from "../components/misc/Wish";
import Footer from "../components/common/Footer";

function WishPage() {
  return (
    <>
      <Header> </Header>
      <Wish></Wish>
      <Footer></Footer>
    </>
  );
}

export default WishPage;

import React from "react";
import Draggable from "react-draggable";

function HelpWindow({ children, title, hasVideo = true, handleVideoClick }) {
  return (
    <Draggable>
      <div className="xp-window w-1/2 flex flex-col">
        <div
          className="xp-title-bar"
          style={{ paddingTop: "14px", paddingBottom: "14px" }}
        >
          <div className="xp-title-bar-text">{title}</div>
          <div className="xp-title-bar-controls">
            <button aria-label="Help" />
            <button aria-label="Close" />
          </div>
        </div>
        <div className="xp-window-body">
          <div className=""> {children}</div>
        </div>

        <div class="xp-status-bar">
          <p class="xp-status-bar-field">Press F1 for help</p>
          <p class="xp-status-bar-field">Slide 1</p>
          <p class="xp-status-bar-field">CPU Usage: 14%</p>
        </div>
      </div>
    </Draggable>
  );
}
export default HelpWindow;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "../node_modules/xp.css/dist/XP.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./pages/ErrorPage";
import WishPage from "./pages/WishPage";
import ContactPage from "./pages/ContactPage";
import ArcadePage from "./pages/ArcadePage";
import About from "./pages/AboutPage";
import { trailingCursor } from "cursor-effects";

import WatchPage from "./pages/VideosPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage></ErrorPage>,
  },
  {
    path: "wish",
    element: <WishPage></WishPage>,
  },

  {
    path: "contact",
    element: <ContactPage></ContactPage>,
  },

  {
    path: "play",
    element: <ArcadePage></ArcadePage>,
  },
  {
    path: "about",
    element: <About></About>,
  },
  { path: "watch", element: <WatchPage></WatchPage> },
]);
new trailingCursor({ particles: 9, rate: 0.6 });
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
import folderImage from "../../images/icons/folder.png";

function WrapperWindow({ children, title }) {
  const [isVisible, setisVisible] = React.useState(true);

  function handleClick() {
    setisVisible(!isVisible);
  }

  if (!isVisible)
    return (
      <div className={isVisible ? "invisible" : "cursor-pointer size-24"}>
        <img
          onClick={handleClick}
          alt="Ping! project (final)"
          src={folderImage}
        />
        {title}
      </div>
    );
  else
    return (
      <div
        className={
          isVisible ? "xp-window w-full h-full flex flex-col" : "invisible"
        }
      >
        <div
          className="xp-title-bar"
          style={{ paddingTop: "14px", paddingBottom: "14px" }}
        >
          <div className="xp-title-bar-text ">{title}</div>
          <div className="xp-title-bar-controls">
            <button onClick={handleClick} aria-label="Minimize" />
            <button aria-label="Maximize" />
            <button onClick={handleClick} aria-label="Close" />
          </div>
        </div>

        <div className="xp-window-body h-full lg:px-48 md:p-8 p-3 justify-center items-center ">
          {children}
        </div>
      </div>
    );
}

export default WrapperWindow;

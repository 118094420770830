import React from "react";

const Video = ({ url }) => {
  return (
    <div className="aspect-video mx-auto items-center justify-center">
      <iframe
        class="md:w-full md:h-96 aspect-video"
        src={url}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  );
};

export default Video;

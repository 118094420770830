import React from "react";
import Draggable from "react-draggable";
import { useState } from "react";
import folderImage from "../../images/icons/folder.png";

function ProgramWindow({
  children,
  title,
  hasVideo,
  FolderTitle = true,
  handleVideoClick,
}) {
  const [isVisible, setisVisible] = useState(true);

  const handleClick = () => {
    if (hasVideo === true) {
      handleVideoClick();
      return;
    }

    setisVisible(!isVisible);
  };

  const handleStart = (event) => {
    event.stopPropagation();
  };

  // if (!isVisible) {
  //   // When isVisible is false and hasVideo is also false, render a button
  //   if (!hasVideo) {
  //       return (
  //
  //       );
  //   }
  //   // If isVisible is false but hasVideo is true, you can add additional rendering logic here
  //   // For example, return null or a different component
  //   return null; // Or any other fallback UI
  // }

  // When isVisible is true, render the main UI

  if (isVisible || hasVideo) {
    return (
      <div className={isVisible ? "visible" : "invisible"}>
        <Draggable onStart={handleStart}>
          <div className="xp-window h-full w-full flex flex-col lg:max-h-full">
            <div
              className="xp-title-bar"
              style={{ paddingTop: "14px", paddingBottom: "14px" }}
            >
              <div className="xp-title-bar-text">{title}</div>
              <div className="xp-title-bar-controls">
                <button onClick={handleClick} aria-label="Minimize" />
                <button aria-label="Maximize" />
                <button onClick={handleClick} aria-label="Close" />
              </div>
            </div>
            <div className="xp-window-body p-3 lg:p-7">{children}</div>
          </div>
        </Draggable>
      </div>
    );
  } else {
    return (
      <div
        className={
          isVisible ? "invisible " : "cursor-pointer size-24 self-start"
        }
      >
        <img
          role="button"
          className="md:size-full size-2/3"
          onClick={handleClick}
          alt="ping project"
          src={folderImage}
        />

        {FolderTitle}
      </div>
    );
  }
}
export default ProgramWindow;

import React, { useState, useEffect } from "react";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import Video from "../components/epk/Video";
import videos from "../utils/videos";

function WatchPage() {
  const url = "https://www.youtube.com/embed/v=XGx1HvLV_NQ";

  useEffect(() => {
    document.title = "Kaizo Kim - Watch";
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", "Videos by the artist");
  }, []);

  if (videos.length == 0) {
    return (
      <div className="h-full overflow-auto ">
        <Header> </Header>
        <div className="h-screen w-full lg:max-w-screen-2xl mx-auto ">
          <div className="h-2/3 text-lg flex flex-col justify-center items-center ">
            You need your 360mg?
          </div>
        </div>
        <Footer> </Footer>
      </div>
    );
  }

  return (
    <div className="h-full overflow-auto ">
      <Header> </Header>
      <div className="h-screen w-full lg:max-w-screen-2xl mx-auto ">
        <div
          id="video-list"
          className="flex flex-col justify-center items-center md:space-y-12 md:mb-72"
        >
          {videos.map((video) => (
            <Video key={video.id} url={video} />
          ))}
        </div>
      </div>

      <Footer> </Footer>
    </div>
  );
}

export default WatchPage;

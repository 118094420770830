import React from "react";

const ArcadeGame = () => {
  const divStyle = {
    position: "relative",
    height: 0,
    paddingBottom: "117.6%",
    overflow: "hidden",
  };

  return (
    <div className="">
      <iframe
        className="aspect-video w-full size-full"
        src="https://microsoft.github.io/pxt-kickoff/"
        allowFullScreen
        sandbox="allow-popups allow-forms allow-scripts allow-same-origin"
        frameborder="0"
      ></iframe>
    </div>
  );
};

export default ArcadeGame;
